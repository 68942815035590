import constantCreator from '../libs/constantCreator'

const createConst = constantCreator('beacon')

export const TRIGGER_INIT_PAGE_VIEW = createConst('TRIGGER_INIT_PAGE_VIEW')
export const initPageView = (props = {}) => ({ type: TRIGGER_INIT_PAGE_VIEW, beacon: { props } })

export const TRIGGER_PAGE_VIEW = createConst('TRIGGER_PAGE_VIEW')
export const triggerPageView = (props = {}) => ({ type: TRIGGER_PAGE_VIEW, beacon: { props } })

export const AMPLITUDE_EVENT_TRACK = createConst('AMPLITUDE_EVENT_TRACK')
export const amplitudeEventTrack = (eventName = '', properties = {}) => ({
  type: AMPLITUDE_EVENT_TRACK,
  beacon: { eventName, properties },
})

export const GTM_EVENT_TRACK = createConst('GTM_EVENT_TRACK')
export const gtmEventTrack = (eventName = '', properties = {}) => ({
  type: GTM_EVENT_TRACK,
  beacon: { eventName, properties },
})

export const TRIGGER_ALL_EVENT_TRACK = createConst('TRIGGER_ALL_EVENT_TRACK')
export const eventTrack = (eventName = '', properties = {}) => ({
  type: TRIGGER_ALL_EVENT_TRACK,
  beacon: { eventName, properties },
})
