import React, { PureComponent } from 'react'

function withReloadPageAfterLogin(BaseComponent) {
  class WrappedWithReloadPageAfterLogin extends PureComponent {
    static async getInitialProps(ctx) {
      if (BaseComponent.getInitialProps) {
        let props = await BaseComponent.getInitialProps(ctx)
        return props
      }
    }
    componentDidMount() {
      window.addEventListener('storage', function (e) {
        if (e.key === 'user.info') {
          window.location.reload()
        }
      })
    }
    render() {
      return <BaseComponent {...this.props} />
    }
  }
  return WrappedWithReloadPageAfterLogin
}

export default withReloadPageAfterLogin
