import React, { PureComponent } from 'react'
import { compose, connect } from './index'
import { eventTrack, gtmEventTrack, amplitudeEventTrack } from '@app-core/beacon/actions'

function withBeacon(BaseComponent) {
  class WrappedWithBeacon extends PureComponent {
    static async getInitialProps(ctx) {
      if (BaseComponent.getInitialProps) {
        let props = await BaseComponent.getInitialProps(ctx)
        return props
      }
      return { namespacesRequired: ctx.namespacesRequired }
    }

    render() {
      return <BaseComponent {...this.props} />
    }
  }

  const mapStateToProps = (state) => ({ ...state })

  return compose(
    connect(mapStateToProps, {
      eventTrack,
      gtmEventTrack,
      amplitudeEventTrack,
    }),
  )(WrappedWithBeacon)
}

export default withBeacon
